module.exports = exports = [
  {
    icon: 'fa-file-invoice',
    title: 'Easy Import & Export',
    description: `Bulk import data through CSV files and export the whole workspace, or just the tables you want into CSV, JSON, HTML or PDF format.`,
    highlight: true
  },
  {
    icon: 'fa-barcode',
    title: 'Barcodes',
    description: `Print barcode labels straight from the app or scan vendor-barcodes and immediately find the data you are looking for.`,
    highlight: true
  },
  {
    icon: 'fa-terminal',
    title: 'REST API',
    description: `Access your workspace through a local REST API. Write your own scripts and process your data the way you want.`,
    highlight: true
  },
  {
    icon: 'fa-bullseye',
    title: 'Offline-first',
    description: `You can access your data whether you have an internet connection or not. Your data is always kept locally.`,
    highlight: true
  },
  {
    icon: 'fa-users',
    title: 'Multi-user',
    description: `Multiple users on different computers can access and work on the same workspace at the same time.`,
    highlight: true
  },
  {
    icon: 'fa-clock',
    title: 'Real-time',
    description: `It runs locally and syncs over the cloud. Users accessing the same workspace will see data being updated in real-time.`,
    highlight: true
  }
]
