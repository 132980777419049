import { render, staticRenderFns } from "./AppFeatures.vue?vue&type=template&id=45f23207&scoped=true&"
import script from "./AppFeatures.vue?vue&type=script&lang=js&"
export * from "./AppFeatures.vue?vue&type=script&lang=js&"
import style0 from "./AppFeatures.vue?vue&type=style&index=0&id=45f23207&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f23207",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FeatureRow: require('/opt/build/repo/components/elements/FeatureRow.vue').default})
