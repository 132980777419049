//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    feature: { type: Object, required: true },
    swap: { type: Boolean, default: false }
  },
  computed: {
    swapDisabled() {
      return ['xs', 'sm'].includes(this.$mq);
    }
  },
  methods: {
    imgUrl(path) {
      return path;
    }
  }
};
