var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"bg-dotted-purple"},[_c('div',{staticClass:"container text-center mb-3"},[(_vm.showTitle)?_c('h2',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_vm._v(" "),_c('feature-row',{attrs:{"feature":{
        icon: 'fa-cubes',
        title: 'Keep track of your inventory'
      }}},[_vm._v("\n      Get to know which parts you have, where they are stored, and when and\n      how they are being used. Quickly access complete part specifications,\n      datasheets and other relevant documents. Keep track of inventory in\n      multiple locations.\n      "),_c('template',{slot:"feature-img"},[_c('img',{attrs:{"src":"/img/1_keep_track_of_your_inventory.svg"}})])],2),_vm._v(" "),_c('feature-row',{attrs:{"swap":true,"feature":{
        icon: 'fa-th-list',
        title: 'Manage your BOMs'
      }}},[_vm._v("\n      List, document and track products. Detailed bill of materials, multiple\n      revisions, access to up-to-date pricing and availability in available\n      suppliers and quickly estimate BOM costs for a given batch quantity.\n      Keep track of your production.\n      "),_c('template',{slot:"feature-img"},[_c('img',{attrs:{"src":"/img/2_manage_your_BOMs.svg","alt":"Manage your BOMs"}})])],2),_vm._v(" "),_c('feature-row',{attrs:{"swap":false,"feature":{
        icon: 'fa-dice',
        title: 'Access the supply chain'
      }}},[_vm._v("\n      Real-time information from the supply chain will help you decide which\n      parts to pick for your products and from which suppliers. Use the\n      information provided for the most common online distributors or enter\n      your own suppliers and custom quotes.\n      "),_c('template',{slot:"feature-img"},[_c('img',{attrs:{"src":"/img/3_access_the_supply_chain.svg","alt":"Access the supply chain"}})])],2),_vm._v(" "),_c('feature-row',{attrs:{"swap":true,"feature":{
        icon: 'fa-shopping-cart',
        title: 'Buy only what you need'
      }}},[_vm._v("\n      Create purchase lists or generate them automatically whenever a\n      production build requires additional inventory. Pick the best quotes\n      based on up-to-date pricing and availability on your preferred\n      suppliers. Quickly merge purchase lists from different products into a\n      single supplier's purchase order.\n      "),_c('template',{slot:"feature-img"},[_c('img',{attrs:{"src":"/img/4_buy_only_what_you_need.svg","width":"480px","alt":"Purchasing"}})])],2),_vm._v(" "),_c('feature-row',{attrs:{"swap":false,"feature":{
        icon: 'fa-industry',
        title: 'Keep track of your production'
      }}},[_vm._v("\n      Reserve inventory as soon as you have it available. Create and export\n      kit lists when fulfilling parts for production so you know where to\n      source parts from. Get your inventory automatically adjusted once\n      production is finished.\n      "),_c('template',{slot:"feature-img"},[_c('img',{attrs:{"src":"/img/5_keep_track_of_your_production.svg","alt":"Production"}})])],2),_vm._v(" "),_c('feature-row',{attrs:{"swap":true,"feature":{
        icon: 'fa-money-check-alt',
        title: 'Manage your sales'
      }}},[_vm._v("\n      Keep track of your customers and sales orders. Get to know if you have\n      enough stock to fulfill a sales order and automatically decrement\n      inventory from stock whenever a sales order is ready to be delivered to\n      your customers.\n      "),_c('template',{slot:"feature-img"},[_c('img',{attrs:{"src":"/img/6_manage_your_sales.svg","alt":"Manage your sales"}})])],2)],1),_vm._v(" "),_c('div',{staticClass:"container wide-container pt-md-4 pb-md-1"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"row row-flex text-left mb-5"},_vm._l((_vm.miniFeatures),function(feature,index){return _c('div',{key:index,staticClass:"col-12 col-lg-4 mb-md-0"},[_c('div',{staticClass:"mini-feature"},[_c('h4',{staticClass:"text-center text-md-left"},[_c('i',{class:("c-yellow fa fas " + (feature.icon) + " mr-1")}),_vm._v("\n              "+_vm._s(feature.title)+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"text-lg c-body2 m-0"},[_vm._v(_vm._s(feature.description))])])])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }