//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FeatureRow from '@/components/elements/FeatureRow.vue';

export default {
  components: { FeatureRow },
  props: {
    showTitle: { type: Boolean, default: true },
    showAll: { type: Boolean, default: true },
    title: { type: String, default: 'Features' }
  },
  data() {
    return {
      miniFeatures: require('~/content/raw/features_mini')
    };
  },
  methods: {}
};
