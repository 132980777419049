import { render, staticRenderFns } from "./index.vue?vue&type=template&id=43a94da3&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=43a94da3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43a94da3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppFeatures: require('/opt/build/repo/components/AppFeatures.vue').default,AppPricing: require('/opt/build/repo/components/AppPricing.vue').default,CallToAction: require('/opt/build/repo/components/elements/CallToAction.vue').default})
