//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PricingCards from '@/components/elements/PricingCards.vue';
export default {
  components: { PricingCards }
};
